<template>
    <v-row dense align="start">
      <v-col cols="5" class="my-4">
        <v-divider class="mx-1"></v-divider>
      </v-col>
      <v-col cols="2" class="text-center">{{ texto }}</v-col>
      <v-col cols="5" class="my-4">
        <v-divider class="mx-1"></v-divider>
      </v-col>
    </v-row>
</template>
<script>
export default {
    props:{
        texto:{
            default:'',
            type: String
        },
    }
}
</script>